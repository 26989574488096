$primary-green : #019474;
// $primary-green : #2a9d8f;
$light-green : #76c893;
$white : #fff;
$light-grey : #f0f5f7;
$dark-grey : #8d99ae;
$darker-grey : #2b2d42;
$black: #000;
$red: #e63946;
$brown: #e76f51;
$yellow: #e9c46a;
$night-blue: #023e8a;

$flag-red: #ce1126;
$flag-yellow: #fcd116;
$flag-green: #009460;