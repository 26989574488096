.mutation {
    .filter-and-search {
      margin-top: 1.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone() {
        flex-direction: column;
        align-items: flex-start;
      }
      .filter {
        select {
          width: 200px;
          padding: 0.5em;
          font-weight: 700;
          background-color: $light-grey;
          border: none;
          outline: none;
          @include phone() {
            width: 100%;
            min-width: 250px;
          }
        }
      }
      .search {
        input[type="text"] {
          padding: 0.5em;
          width: 200px;
          @include phone() {
            width: 100%;
          }
        }
      }
    }
    .datagrid {
      margin-top: 2em;
    }
    .options {
        button {
            font-size: 0.7rem;
            padding: 0.7em;
            text-transform: uppercase;
            // background-color: $dark-grey;
            // color: $white;
        }
    }

    // .mutation-form {
    //   text-align: left;
    //   select {
    //     width: 100%;
    //   }
    // }
  }
  