.details-immatriculation {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @include tablet() {
    width: 90%;
  }
  .license {
    display: inline-block;
    margin: 1em 0;
  }
  .infos {
    margin-top: 2em;
    h3 {
      margin-bottom: 1em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.069);
      color: $primary-green;
    }
    .info-group {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 1em;
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-bottom: none;
      > div {
        display: flex;
        flex-direction: column;
        span {
          font-size: 0.8rem;
          font-weight: 700;
          color: $dark-grey;
          margin-bottom: 0.3em;
        }
        strong {
          font-size: 0.95rem;
        }
      }
      .numero-immatriculation {
        &.green {
          color: $primary-green;
        }
        &.red {
          color: $red;
        }
      }
    }
    .image {
      margin: 0.5em 0;
      width: 120px;
      height: 150px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
      &.file-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &:hover{
          &::after{
            display: block;
          }
        }
        &::after{
          content: 'Cliquez pour telecharger';
          position: absolute;
          top: 90%;
          left: 0%;
          font-size: 0.7rem;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.503);
          display: none;
          white-space: nowrap;
        }
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 1em;
    @include phone() {
      flex-direction: column;
    }
    button {
      width: 150px;
      @include phone() {
        width: 100%;
      }
      &.validate {
        margin-right: 1em;
        @include phone() {
          margin-right: 0;
        }
      }
      &.reject-btn {
        background-color: $red;
      }
    }
  }
}
