@mixin phone {
    @media (max-width: 599px) { @content; }
  }
  @mixin tablet {
    @media (max-width: 900px) { @content; }
  }
  // @mixin tablet-landscape {
  //   @media (min-width: 900px) { @content; }
  // }
  @mixin desktop {
    @media (max-width: 1200px) { @content; }
  }
  @mixin big-desktop {
    @media (max-width: 1800px) { @content; }
  }