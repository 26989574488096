.dashboard {
  .greetings {
    margin-bottom: 1.5em;
    span {
      font-size: 0.85rem;
    }
  }
  .stats {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .stat-box {
      display: flex;
      justify-content: space-between;
      background-color: $light-grey;
      padding: 1em 1.5em;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
          transform: scale(1.05);
      }
      .box-left {
        .value {
          font-size: 1.8rem;
          font-weight: 700;
        }
        .text {
          margin-top: 1.5em;
          font-size: 0.95rem;
        }
      }
      .box-right {
        font-size: 2.5em;
      }
    }
  }
  .charts {
    margin-top: 1em;
    h2 {
        margin: 2em 0 1em;
    }
    h4 {
      margin-bottom: 1em;
    }
    .graphes {
      display: grid;
      gap: 2em;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      > div {
        padding: 1em;
        background-color: $light-grey;
      }
      .bar-chart {
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1em;
          h4 {
            margin-bottom: 0;
          }
          .month {
            select {
              border: none;
            }
          }
        }
      }
    }
  }
}
