.login-page {
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }
  .header-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-family: $century;
      font-weight: 700;
      text-transform: uppercase;
      &:first-child {
        letter-spacing: 0.08em;
        font-size: 1.3rem;
      }
      &:last-child {
        font-size: 0.9rem;
      }
    }
  }

  .header-2 {
    text-align: center;
    p {
      font-size: 0.95rem;
      font-family: $century;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .armoiries {
    div {
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .motif {
    position: absolute;
    width: 60px;
    &.ellipse1 {
      top: 0;
      right: 0;
      width: 100px;
      @include phone() {
        display: none;
      }
    }
    &.ellipse2 {
      bottom: 0;
      right: 20%;
      width: 70px;
    }
    &.star {
      top: 8%;
      left: 5%;
      @include phone() {
        display: none;
      }
    }
    &.line1 {
      bottom: 20%;
      left: 0;
    }
    &.rectangle1 {
      bottom: 30%;
      right: 10%;
    }
    &.polygon1 {
      top: 45%;
      left: 20%;
    }
  }
  .form {
    position: relative;
    z-index: 2;
    width: 30%;
    background-color: $white;
    padding: 4em 3em;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // background: url("./../../assets/logo.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include tablet() {
      width: 60%;
    }
    @include phone() {
      width: 90%;
      padding: 2em 1em;
    }
    .logo {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      margin-bottom: 1em;
      .image {
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      p {
        font-size: 0.95rem;
        font-weight: 700;
        margin-bottom: 0.5em;
      }
    }

    h3 {
      margin-bottom: 1em;
      font-size: 1rem;
      @include phone() {
        font-size: 0.95rem;
      }
    }
    button {
      margin-top: 1.5em;
    }
    input {
      border-bottom: 1px solid $dark-grey;
    }
    input[type="text"] {
      margin-bottom: 1em;
    }
    .forgot {
      margin-top: 1em;
      font-size: 0.7rem;
      color: $darker-grey;
      text-decoration: underline;
      font-weight: 700;
    }
  }
  .footer {
    p {
      font-size: 0.85rem;
      font-weight: 700;
      color: $darker-grey;
      font-family: $century;
    }
  }
}
